/* File: src/components/Footer/Footer.module.css */

.wrapper {
  background-color: #fff;
  padding: 20px;
  font-family: 'Montserrat Light Alt1', sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footerInner {
  max-width: var(--container-width, 1220px);
  margin: 0 auto;
  padding: 0 clamp(1rem, 4vw, 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.footerLogo {
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.footerLinks {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  gap: clamp(0.5rem, 2vw, 2rem);
  flex-wrap: wrap;
  justify-content: center;
}

.footerLinks li a {
  color: #333;
  text-decoration: none;
  font-size: clamp(0.8rem, 1.5vw, 0.9rem);
  transition: color 0.2s ease;
  white-space: nowrap;
}

.footerLinks li a:hover {
  color: #1976d2;
}

@media screen and (max-width: 600px) {
  .wrapper {
    position: static;
    padding: 16px;
  }

  .footerInner {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .footerLinks {
    width: 100%;
    justify-content: center;
    gap: 1rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .footerInner {
    padding: 0 1rem;
  }
}

@media screen and (min-width: 1921px) {
  .footerInner {
    max-width: 1400px;
  }
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.mainContent {
  flex: 1;
  background-color: #f5f5f5;
  padding-top: 80px;
  padding-bottom: calc(60px + 1rem);
  /* Account for footer height plus some spacing */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.appContent {
  flex: 1 0 auto;
  padding: 20px;
  padding-bottom: 80px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.authError {
  background-color: #fff3cd;
  color: #856404;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  text-align: center;
}

.authCallback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  text-align: center;
  color: #666;
}

@media screen and (max-width: 600px) {
  .mainContent {
    padding-top: 60px;
    padding-bottom: 1rem;
    /* Remove footer spacing since footer is static on mobile */
  }

  .container {
    max-width: 95%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .mainContent {
    padding-top: 60px;
  }

  .container {
    max-width: 95%;
  }
}

@media screen and (min-width: 1921px) {
  .container {
    max-width: 1800px;
  }
}
.modal {
    min-width: 400px;
}

.userInfo {
    padding: 16px;
}

.userInfo h2 {
    margin-bottom: 16px;
    color: var(--color-text-primary);
}

.userInfo p {
    margin-bottom: 8px;
    color: var(--color-text-secondary);
}

.userInfo strong {
    color: var(--color-text-primary);
    margin-right: 8px;
}

.error {
    color: var(--color-error);
    text-align: center;
    padding: 16px;
}
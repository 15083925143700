.debugButton {
    background-color: white;
    color: #333;
    border: 1px solid #333;
    padding: clamp(6px, 1vw, 8px) clamp(12px, 2vw, 16px);
    border-radius: 4px;
    cursor: pointer;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    font-family: 'Montserrat Light Alt1', sans-serif;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.debugButton:hover {
    background-color: #f5f5f5;
}

.debugInfo {
    position: fixed;
    top: 70px;
    right: 20px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    margin-top: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    max-width: 500px;
    max-height: 80vh;
    overflow: auto;
}

.debugInfo pre {
    margin: 8px 0 0;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 12px;
    font-family: monospace;
}
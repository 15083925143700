/* File: src/pages/HomePage.module.css */

.container {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  width: 100%;
  box-sizing: border-box;
  /* Include padding in width calculation */
}

.columnsContainer {
  display: flex;
  gap: 2rem;
  flex: 1;
  min-height: 0;
  /* Allow container to shrink */
}

.column {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* Prevent content overflow */
  min-height: 400px;
  /* Ensure minimum height for content */
}

.columnTitle {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  color: #333;
  flex-shrink: 0;
  /* Prevent title from shrinking */
}

.uploadSection {
  margin-bottom: 1.5rem;
  flex-shrink: 0;
  /* Prevent upload section from shrinking */
}

/* Ensure the upload area has a nice style */
.uploadSection :global(.ant-upload-drag) {
  border: 2px dashed #d9d9d9;
  border-radius: 8px;
  background: #fafafa;
  transition: border-color 0.3s;
}

.uploadSection :global(.ant-upload-drag:hover) {
  border-color: #1890ff;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .columnsContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .column {
    min-height: 300px;
    margin-bottom: 1rem;
  }
}
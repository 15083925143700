*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100vh;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "MontserratAlt1";
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlt1-Light.woff2") format("woff2"),
    url("./assets/fonts/MontserratAlt1-Light.woff") format("woff");
}

body {
  margin: 0;
  font-family: "MontserratAlt1", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.filesListWrapper {
    width: 100%;
    min-height: 200px;
    position: relative;
}

.filesList {
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
}

.fileItem {
    cursor: pointer;
    padding: 12px !important;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.fileItem:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.selected {
    background-color: rgba(0, 0, 0, 0.04);
}

.deleteButton {
    opacity: 0.6;
    transition: opacity 0.3s ease;
}

.deleteButton:hover {
    opacity: 1;
    color: #ff4d4f;
}

.ratingContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.stars {
    display: flex;
    gap: 2px;
}

.starFilled {
    color: #fadb14;
}

.starOutline {
    color: #d9d9d9;
}

.percentage {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}
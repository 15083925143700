.wrapper {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.navInner {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: var(--container-width, 1220px);
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.burgerWrapper {
    display: none;
    outline: none;
    border: 0;
    background-color: transparent;
    height: 100%;
    padding: 0 15px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 24px;
}

.burgerWrapper:hover {
    opacity: 0.7;
}

.navLinks {
    display: flex;
    align-items: center;
    gap: clamp(0.5rem, 2vw, 1.25rem);
}

.userInfo {
    display: flex;
    align-items: center;
    gap: clamp(0.5rem, 2vw, 1rem);
}

.username {
    font-family: 'Montserrat Light Alt1', sans-serif;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: clamp(100px, 20vw, 200px);
}

.usernameButton {
    background: none;
    border: none;
    color: var(--color-text-primary);
    font-size: 1rem;
    padding: 4px 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 4px;
}

.usernameButton:hover {
    background-color: var(--color-background-hover);
}

@media screen and (max-width: 768px) {
    .navLinks {
        display: none;
    }

    .burgerWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navInner {
        padding: 0 0.5rem;
    }
}

@media (hover: none) and (pointer: coarse) {
    .burgerWrapper {
        min-height: 44px;
        min-width: 44px;
    }
}

@media screen and (min-width: 1921px) {
    .navInner {
        max-width: 1400px;
    }
}